<template>
	<div>
		<!--	Banner	-->
		<div class="Banner">
			<div class="Banner_content">
				<h1>融恒所智能化管理系统</h1>
				<p>做千万家律所</p>
				<p>用得起的软件</p>
				<a class="btn" @click="login(1)"><span>一键开启标准化 </span> <i class="el-icon-arrow-right"></i></a>
				<ul class="Banner_list">
					<li><span style="font-weight: bold">客户</span></li>
					<li><span style="font-weight: bold">案件</span></li>
					<li><span style="font-weight: bold">财务</span></li>
					<li><span style="font-weight: bold">行政</span></li>
					
					<li><i class="el-icon-check"></i><span>客户登记</span></li>
					<li><i class="el-icon-check"></i><span>案件收录</span></li>
					<li><i class="el-icon-check"></i><span>费用管理</span></li>
					<li><i class="el-icon-check"></i><span>印章管理</span></li>
					<li><i class="el-icon-check"></i><span>客户跟踪</span></li>
					<li><i class="el-icon-check"></i><span>风控预检</span></li>
					<li><i class="el-icon-check"></i><span> 票据管理</span></li>
					<li><i class="el-icon-check"></i><span> 证件管理</span></li>
					<li><i class="el-icon-check"></i><span>客户查询</span></li>
					<li><i class="el-icon-check"></i><span>案件公函</span></li>
					<li><i class="el-icon-check"></i><span>收支管理</span></li>
					<li><i class="el-icon-check"></i><span>资产管理</span></li>
					<li><i class="el-icon-check"></i><span>客户转化</span></li>
					<li><i class="el-icon-check"></i><span>案件合同</span></li>
					<li><i class="el-icon-check"></i><span>律师提款</span></li>
					<li><i class="el-icon-check"></i><span>用品管理</span></li>
					<li></li>
					<li><i class="el-icon-check"></i><span>案件委托书 </span></li>
					<li></li>
					<li><i class="el-icon-check"></i><span>人事管理 </span></li>
				
				</ul>
			</div>
		</div>
		<!--	专业的律师事务所管理系统	-->
		<div class="manage-item01">
			<div class="wrapper">
				<h1>专业的律师事务所管理系统</h1>
				<ul class="clearfix">
					<li>
						<div class="pic">
							<img alt="图片" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/1.2.jpg">
						</div>
						<div class="desc">
							<h3>客户管理</h3>
							<p>建立一统一的客户档案，能够实现登记，跟 踪、转化等相关功能。</p>
						</div>
						<a href="https://www.zcerm.com/?iq=law&amp;i=crm" target="_blank">
							<div class="btn" @click="login(1)"><span>注册</span><i class="el-icon-arrow-right"></i></div>
						</a>
					</li>
					<li>
						<div class="pic">
							<img alt="图片" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/1.1.jpg">
						</div>
						<div class="desc">
							<h3>案件管理 </h3>
							<p>能自动生成统一案号，自动预检案件是否有利益冲突、能自动生成该案件的公函、委托书、代理合同等相关功能，可以手机端实时审批查询案件进程。</p>
						</div>
						<a href="https://www.zcerm.com/?iq=law&amp;i=crm" target="_blank">
							<div class="btn" @click="login(2)"><span>登陆</span><i class="el-icon-arrow-right"></i></div>
						</a>
					</li>
					<li>
						<div class="pic">
							<img alt="图片" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/1.3.jpg">
						</div>
						<div class="desc">
							<h3>财务人事管理 </h3>
							<p>能统一管理所内的财务数据、从开票、收款、律师提款整个律所的财务管理，以及律所内人事的管理。</p>
						</div>
						<a>
							<div class="btn" @click="login(3)"><span>试用账户</span><i class="el-icon-arrow-right"></i></div>
						</a>
					</li>
				</ul>
			</div>
		</div>
		
		<!--	更安全的管理企业客户资料	-->
		<div class="manage-item02">
			<div class="wrapper">
				<div class="manage-item02-top">
					<h1>更安全的管理律所客户资料</h1>
					<p>可以帮助律师事务所高效地处理各种业务和管理事项</p>
				</div>
				<ul class="manage-item02-list clearfix">
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>客户管理</h2>
						<p>客户登记、客户查询、智能跟踪、客户转化能在手机端电脑端同步添加或查询客户实现对客户管理的信息化。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>委托人管理</h2>
						<p>可实时添加登记委托人的相关信息，分配给该委托人的主办律师，实时在线查询委托人的相关信息。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>案件管理</h2>
						<p>实现对案件的，收录、预检、审批、盖章、收款实现电子化，信息化可随时统计律所所有的案件信息汇总，并能自动生成案件的案号，对案件的管理实现数字化管理。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>财务管理</h2>
						<p>实现对整个律所的，开票管理、费用报销管理、收支登记管理能自动生成律所的利润表，随时可查询到整个律所的运营的财务状况，案件的应收款、已收款等数据。</p>
					</li>
				</ul>
				<ul class="manage-item02-list clearfix">
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>人事管理</h2>
						<p>实现对整个律所的人员花名册的登记，律所办公人员劳务合同管理，整个律所的组织架构，权限分配合同是否到期等各种数据实时可查。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>行政管理</h2>
						<p>对律所的印章、文件、资产、办公用品的数字化管理，各种资产及办公设备的领用情况，资产的折旧明细等数据实时可查。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>公函合同管理</h2>
						<p>对整个律所的公函、代理合同、委托书实现统一数字化管理，自动生成函号，合同号可随时完成对公函和合同的在线审批管理功能。</p>
					</li>
					<li>
						<span><i class="el-icon-success"></i></span>
						<h2>电子印章</h2>
						<p>可在线对，案件的公函、委托书、代理合同在线盖章，方便律所的异地用章。 </p>
					</li>
				</ul>
			</div>
		</div>
		<!--		图文区域-->
		
		<div class="imageText">
			<div class="wrapper">
				<div class="imageText-text  imageText-left">
					<div class="imageText-text-content ">
						<h2><span></span><i></i></h2>
						<h1>案件管理</h1>
						<p style="margin-bottom: 20px;">
							用于协助律师事务所对其接受的案件进行管理和跟踪，从而提高律所的工作效率和服务质量。
							<!--							案件创建，案件查询，案件咨询，案件调解，文书下载，会见提醒，文书申请，为律所与委托人之间的沟通提供了便利，大大提高了律所的工作效率和服务质量。-->
						</p>
					</div>
				</div>
				<transition name="fade">
					<div v-if="viewVisibleHeight > 1600 && viewVisibleHeight < 3000" id="Leftone" class="imageText-image imageText-right ">
						<p>
							<span></span>
							<span></span>
							<span></span>
						</p>
						<img alt="律所oa" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/%E6%A1%88%E4%BB%B6.png" title="律所oa">
					</div>
				</transition>
			</div>
		</div>
		
		<div class="imageText">
			<div class="wrapper">
				<div class="imageText-text  imageText-right">
					<div class="imageText-text-content ">
						<h2><span></span><i></i></h2>
						<h1>案件预检</h1>
						<p style="margin-bottom: 20px;">用于协助律师事务所在接受客户委托之前，对可能出现的法律问题进行初步评估和风险提示，从而为律所决策和客户提供更加准确和可靠的法律服务。</p>
					</div>
				</div>
				<transition name="fadeLeft">
					
					<div v-if="viewVisibleHeight > 2200 && viewVisibleHeight < 3600" id="Leftone" class="imageText-image imageText-left">
						<p>
							<span></span>
							<span></span>
							<span></span>
						</p>
						<img alt="客户管理系统" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/%E6%A1%88%E4%BB%B6%E9%A2%84%E6%A3%80.png" title="客户管理系统">
					</div>
				</transition>
			</div>
		</div>
		
		<div class="imageText">
			<div class="wrapper">
				<div class="imageText-text  imageText-left">
					<div class="imageText-text-content ">
						<h2><span></span><i></i></h2>
						<h1>行政管理</h1>
						<p style="margin-bottom: 20px;">
							用于协助律师事务所管理其内部的行政事务，例如人力资源管理、财务管理等，从而提高律所的管理效率和运营效益。
						</p>
					</div>
				</div>
				<transition name="fade">
					<div v-if="viewVisibleHeight > 3000 && viewVisibleHeight < 4300" id="Leftone" class="imageText-image imageText-right ">
						<p>
							<span></span>
							<span></span>
							<span></span>
						</p>
						<img alt="律所oa" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/%E8%A1%8C%E6%94%BF.png" title="律所oa">
					</div>
				</transition>
			</div>
		</div>
		
		<div class="imageText">
			<div class="wrapper">
				<div class="imageText-text  imageText-right">
					<div class="imageText-text-content ">
						<h2><span></span><i></i></h2>
						<h1>电子印章</h1>
						<p style="margin-bottom: 20px;">用于协助律师事务所进行电子印章的管理和使用，从而提高律所的工作效率和安全性。</p>
					</div>
				</div>
				<transition name="fadeLeft">
					<div v-if="viewVisibleHeight > 3600 && viewVisibleHeight < 4600" class="imageText-image imageText-left">
						<p>
							<span></span>
							<span></span>
							<span></span>
						</p>
						<img alt="客户管理系统" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/website/%E7%94%B5%E5%AD%90%E5%8D%B0%E7%AB%A0.png" title="客户管理系统">
					</div>
				</transition>
			</div>
		</div>
		<!--		核心区域-->
		<div class="core">
			<div class="wrapper">
				<h1>律所专用的律所办公管理软件</h1>
				<p>专业、高效、严谨，为律所更好的完成案件管理工作，让律所更好的管理手头委托代理人档案和开庭案件开庭提醒工作，将更多精力让在为委托人获得更大胜诉概率上</p>
				
				<div class="btn" @click="login(1)">
					律师办公系统
				</div>
			</div>
		</div>
		<!--		底部 -->
		<footers></footers>
		<!--		试用-->
		<el-dialog :visible.sync="centerDialogVisible" center title="热门行业公用账户" width="40%">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column label="行业名称" prop="HY" width="180">
				</el-table-column>
				<el-table-column label="账户名称" prop="Name" width="180">
				</el-table-column>
				<el-table-column label="账户" prop="ZH" width="180">
				</el-table-column>
				<el-table-column label="密码" prop="pass">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import footers from '../FOOTER.vue'

export default {
	name: "LawFirm",
	components: {
		footers
	},
	data() {
		return {
			centerDialogVisible: false,//试用账户弹窗
			tableData: [{
				HY: '进销存管理系统',
				Name: 'JXCDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: 'OA办公',
				Name: 'OADemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '美容美发行业',
				Name: 'MYDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '汽修行业',
				Name: 'QXDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '商超零售行业',
				Name: 'SCDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '智能报修行业',
				Name: 'BXDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '工程ERP',
				Name: 'ERPtest',
				ZH: 'admin',
				pass: '123'
			}],
			viewVisibleHeight: 0,
		}
	},
	mounted() {
		// 页面加载完毕加载
		window.addEventListener('scroll', ev => {
			this.viewVisibleHeight = window.pageYOffset
			// console.log(window.pageYOffset)
		}, true)
	},
	methods: {
		login(index) {
			if (index === 1) {
				//注册
				window.open('http://rhdgjzc.ronghengzn.com/', '_blank');
			} else if (index === 2) {
				//	登录
				window.open('http://rhdgj.ronghengzn.com/', '_blank');
			} else if (index === 3) {
				this.centerDialogVisible = true
			}
		}
	}
}
</script>

<style lang="less" scoped>
@theme: #364A6B;
@theme-lightGray: #f6f6f6;

@subtopic: #6786b9;
@text-white: #fff;
@import './Banner.less';
@import './item01.less';


.wrapper {
	width: 1200px;
	min-width: 1200px;
	padding: 0 30px;
	box-sizing: border-box;
	margin: 0 auto;
}

//区域二

.manage-item02 {
	width: 100%;
	min-width: 1200px;
	background: #fbfcfd;
	padding: 160px 0;
	
	.manage-item02-top {
		h1 {
			margin-top: 8px;
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 10px;
			color: #232427;
		}
		
		p {
			font-size: 18px;
			font-weight: 300;
			color: #333;
		}
	}
	
	.manage-item02-list {
		margin-top: 80px;
		
		li {
			width: 25%;
			float: left;
			box-sizing: border-box;
			padding-right: 30px;
			
			span {
				display: block;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				color: @text-white;
				background-color: @theme;
				text-align: center;
				line-height: 40px;
				font-size: 20px;
			}
			
			h2 {
				color: #232427;
				font-size: 18px;
				margin-top: 15px;
				margin-bottom: 10px;
				font-weight: bold;
			}
			
			p {
				color: #383a40;
				font-size: 17px;
				line-height: 28px;
				font-weight: 300;
			}
		}
		
		
	}
}

@media screen and (max-width: 900px) {
	.wrapper {
		width: 100vw;
		min-width: 100vw;
		overflow: hidden;
	}
	
	//	区域二
	.manage-item02 {
		width: 100vw;
		min-width: 100vw;
		padding: 20px 0;
		
		.manage-item02-top {
			h1 {
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 10px;
			}
			
			p {
				font-size: 14px;
			}
		}
		
		.manage-item02-list {
			margin-top: 10px;
			padding-left: 10px;
			
			li {
				width: 100%;
				//float: left;
				box-sizing: border-box;
				padding-right: 30px;
				
				span {
					display: inline-block;
					width: 30px;
					height: 30px;
					font-size: 20px;
					line-height: 30px;
					margin-right: 10px;
				}
				
				h2 {
					display: inline-block;
					color: #232427;
					font-size: 16px;
					//margin-top: 15px;
					//margin-bottom: 10px;
					font-weight: bold;
				}
				
				p {
					width: 100%;
					color: #383a40;
					font-size: 14px;
					line-height: 28px;
					font-weight: 300;
				}
			}
			
			
		}
	}
	
	.imageText {
		display: none;
	}
	
	
}


//图文区域
.imageText {
	width: 100%;
	min-width: 1200px;
	background: @text-white;
	padding: 100px 0;
	position: relative;
	
	.wrapper {
		height: 500px;
		position: relative;
	}
	
	.imageText-text {
		background-color: #f4f5f8;
		width: 818px;
		height: 500px;
		box-sizing: border-box;
		padding-top: 100px;
		z-index: 0;
		
		h2 > span {
			display: inline-block;
			width: 124px;
			height: 6px;
			border-radius: 10px;
			background-color: @theme;
		}
		
		h2 > i {
			display: inline-block;
			width: 6px;
			height: 6px;
			border-radius: 20px;
			background-color: @theme;
			margin-left: 12px;
		}
		
		h1 {
			font-size: 36px;
			line-height: 40px;
			font-weight: 500;
			color: #393a3d;
			margin-top: 30px;
			margin-bottom: 20px;
		}
		
		p {
			width: 500px;
			font-size: 18px;
			font-weight: 400;
			margin-bottom: 38px;
			line-height: 33px;
			color: #393a3d;
		}
	}
	
	.imageText-image {
		opacity: 1;
		z-index: 1;
		position: absolute;
		top: 100px;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
		border: 11px solid #f3f3f3;
		border-top: 22px solid #f3f3f3;
		border-radius: 5px 5px 0 0;
		background: #f4f5f8;
		cursor: pointer;
		width: 630px;
		
		
		p {
			position: absolute;
			top: -20px;
			right: 10px;
		}
		
		span {
			display: inline-block;
			width: 11px;
			height: 11px;
			border-radius: 100%;
			background: @text-white;
			margin-left: 4px;
			margin-bottom: 2px;
		}
		
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

//图文右侧
.imageText-right {
	
	background-color: #f4f5f8;
	width: 818px;
	box-sizing: border-box;
	
	
	position: absolute;
	top: 0;
	right: 0;
	
	.imageText-text-content {
		padding-left: 300px;
		
	}
}

//图文左侧
.imageText-left {
	.imageText-text-content {
		padding-left: 60px;
	}
	
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
}

//图文动画
.fadeLeft-enter-active, .fadeLeft-leave-active {
	animation: enterLeft .5s;
	
}

.fade-enter-active, .fade-leave-active {
	animation: enter .5s;
}

@keyframes enterLeft {
	0% {
		transform: translateX(-200px);
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	20% {
		opacity: 0.2;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}

@keyframes enter {
	0% {
		transform: translate(200px, 200px);
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	20% {
		opacity: 0.2;
	}
	100% {
		transform: translate(0px, 0px);
		opacity: 1;
	}
}

//核心区域
.core {
	text-align: center;
	color: #0a0a36;
	margin-bottom: 50px;
	
	h1 {
		font-size: 48px;
		margin-bottom: 32px;
		text-align: center;
	}
	
	p {
		width: 900px;
		margin: 0 auto;
		font-size: 18px;
		line-height: 32px;
	}
	
	.btn {
		margin: 0 auto;
		width: 220px;
		height: 66px;
		line-height: 50px;
		background: @theme;
		border-radius: 100px;
		color: @text-white;
		font-size: 19px;
		font-weight: bold;
		text-align: center;
		cursor: pointer;
		margin-top: 90px;
		border: solid 2px @theme;
		transition: width .5s;
		
	}
	
	.btn:hover {
		width: 300px;
		background-color: rgba(0, 0, 0, 0);
		color: @theme;
		
	}
	
}

@media screen and (max-width: 800px) {
	.core {
		text-align: center;
		margin-bottom: 50px;
		
		h1 {
			font-size: 30px;
			margin-bottom: 20px;
		}
		
		p {
			width: 100%;
			margin: 0 auto;
			font-size: 16px;
			line-height: 25px;
		}
		
		.btn {
			width: 220px;
			height: 50px;
			line-height: 35px;
			font-size: 16px;
			margin-top: 50px;
		}
		
		.btn:hover {
			width: 300px;
			background-color: rgba(0, 0, 0, 0);
			color: @theme;
			
		}
	}
}

</style>
